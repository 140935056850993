import { type FC, type PropsWithChildren } from 'react';

import { StyledZeroStateCardContainer, StyledZeroStateCardDescription, StyledZeroStateCardLink } from './styled';

export type ZeroStateCardProps = {
  highlightedText?: string;
  normalText?: string;
  linkLabel?: string;
  linkHref?: string;
  isAnchor?: boolean;
};

export const ZeroStateCard: FC<PropsWithChildren<ZeroStateCardProps>> = ({
  highlightedText,
  normalText,
  linkLabel,
  linkHref,
  isAnchor = false,
}) => (
  <StyledZeroStateCardContainer>
    <StyledZeroStateCardDescription>
      {highlightedText && <b>{highlightedText} </b>}
      {normalText}
    </StyledZeroStateCardDescription>
    {linkLabel && (
      <StyledZeroStateCardLink href={linkHref} isAnchor={isAnchor}>
        {linkLabel}
      </StyledZeroStateCardLink>
    )}
  </StyledZeroStateCardContainer>
);
