import { useCallback } from 'react';

import { createFeatureFlagsInstance } from '@littleotter/zz-flags/react';

import { LAUNCH_DARKLY } from '$configs/env';
import { STORAGE_KEY, useSessionStorage } from '$shared/hooks/useStorage';

import { logger } from '../logging';

/**
 * Fallback Feature Flag Values
 * These values will automatically be loaded as fallbacks by our Feature Flag Service in the event of a service outage or network failure.
 *
 * ! Do not consume this file directly anywhere in this application.
 *
 * ! These keys are camelCased for improved developer ergonomics, but we currently resolve
 * ! these to kebab-cased keys in our feature flag service. Make sure your camelCased keys accurately map to the
 * ! kebab-cased keys launch darkly uses.
 */
const featureFlagFallbackValues = {
  killSwitchAppSignup: false,
  killSwitchAppLogin: false,
  enableNewInsuranceFlow: false,
};

/**
 * Options passed to the Feature Flag Service
 */
export const featureFlagClientConfig = {
  clientSideID: LAUNCH_DARKLY.CLIENT_ID,
  options: {
    streaming: true,
  },
  flags: featureFlagFallbackValues,
};

/**
 * Create our Feature Flag Provider, Hook, & Components with the feature flag factory.
 * This allows us to strongly type our feature flags and their values within our application.
 */
const { ProviderPromise, flagsHook, clientHook, ToggleComponent } = createFeatureFlagsInstance(featureFlagClientConfig);

/**
 * Official React Context Provider for Little Otter's Feature Flag Service
 */
export const getFeatureFlagProvider = ProviderPromise;

/**
 * A hook to retrieve flag values from Little Otter's Feature Flag Service
 */
export const useFeatureFlags = flagsHook;

/**
 * A hook to retrieve the client instance from Little Otter's Feature Flag Service
 */
export const useFeatureFlagClient = clientHook;

/**
 * A component that allows for straightforward conditional rendering of its children based on a
 * Feature Flag's boolean value.
 *
 * Example Usage:
 *
 * <FeatureToggle feature="flagKey">
 *  <FeatureToggle.On>
 *    <div>Feature is on</div>
 *  </FeatureToggle.On>
 *  <FeatureToggle.Off>
 *    <div>Feature is off</div>
 *  </FeatureToggle.Off>
 * </FeatureToggle>
 */
export const FeatureToggle = ToggleComponent;

export interface IdentityManager {
  setIdentity: (userId: string) => void;
  refreshIdentity: () => void;
  clearIdentity: () => void;
}

// TODO: eventually move this into @littleotter/zz-flags
export const useFeatureFlagIdentityManager = (): IdentityManager => {
  const [ffUserId, setFfUserId] = useSessionStorage(STORAGE_KEY.FF_USER_ID, '');
  const ffClient = useFeatureFlagClient();

  const setIdentity = useCallback(
    (userId: string) => {
      logger.debug('SETTING IDENTITY...');
      if (userId !== ffClient.getContext().key) {
        logger.debug('SETTING IDENTITY...actually doing work');
        setFfUserId(userId);
        ffClient.identify({ kind: 'user', key: userId });
      }
      logger.debug('SETTING IDENTITY...DONE');
    },
    [ffClient, setFfUserId]
  );

  const refreshIdentity = useCallback(() => {
    logger.debug(`REFRESHING IDENTITY... for ${ffUserId}`);
    if (ffUserId) {
      logger.debug('REFRESHING IDENTITY...actually doing work');
      setIdentity(ffUserId);
    }
    logger.debug('REFRESHING IDENTITY...DONE');
  }, [ffUserId, setIdentity]);

  const clearIdentity = useCallback(() => {
    logger.debug('CLEARING IDENTITY...');
    setFfUserId('');
    ffClient.identify({ kind: 'user', anonymous: true });
    logger.debug('CLEARING IDENTITY...DONE');
  }, [ffClient, setFfUserId]);

  return { setIdentity, refreshIdentity, clearIdentity };
};
