type BaseQueryParams<QueryParams> = Record<keyof QueryParams, string | string[] | boolean | undefined>;

export const parseSearchString = <QueryParams extends BaseQueryParams<QueryParams>>(queryString: string): QueryParams =>
  queryString
    .replace(/^\?/, '')
    .split('&')
    .reduce((locationQuery, declaration) => {
      const [prop, encodedValueStr] = declaration.split('=');
      if (!prop || !encodedValueStr) return { ...locationQuery };

      const encodedValues = encodedValueStr.split(',');
      const values = encodedValues.map((encodedValue) => decodeURIComponent(encodedValue));
      const value = values.length > 1 ? values : values[0];

      return {
        ...locationQuery,
        [prop]: value,
      };
    }, {} as QueryParams);

export const stringifySearchParams = <QueryParams extends BaseQueryParams<QueryParams>>(
  queryParams: Partial<QueryParams>
): string =>
  Object.keys(queryParams)
    .sort()
    .reduce((locationQuery, key) => {
      const value = queryParams[key as keyof QueryParams];
      if (value === undefined) return [...locationQuery];

      const values = (Array.isArray(value) ? value : [value]) as string[];
      const encodedValues = values.map((item) => encodeURIComponent(item));
      const encodedValueStr = encodedValues.join(',');

      return [...locationQuery, `${key}=${encodedValueStr}`];
    }, [] as string[])
    .join('&')
    .replace(/^/, '?');
