/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-empty */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { type ILoggerService } from '../../../../logging/types';

/**
 * Initializes the Rudderstack SDK
 * @see https://www.rudderstack.com/docs/user-guides/how-to-guides/rudderstack-jamstack-integration/rudderstack-nextjs-integration/
 *
 * Rudderstack provides us this snippet to initialize their SDK. For the most part we're using it as-is (except for a
 * guard against undefined configs and a log on successful load). This means we have
 * to disable a bunch of eslint and typescripts rules to get it to work, but it's worth it to avoid
 * having to maintain a very custom version of this snippet.
 *
 * ! IMPORTANT: Rudderstack recommends calling `page` immediately when the SDK mounts (after the `ready` event), but the
 * ! issue with that is the first page view won't have app metadata (like feature flags) attached. For this reason,
 * ! we're going to manually handle the initial page view in the consumer app implementation.
 */
export const initRudderstackSdk = async (
  logger: ILoggerService,
  dataplaneUrl?: string,
  writeKey?: string,
  sdkBaseUrl?: string
) => {
  if (!dataplaneUrl || !writeKey || !sdkBaseUrl) {
    return;
  }

  (function () {
    'use strict';
    window.RudderSnippetVersion = '3.0.30';
    const identifier = 'rudderanalytics';
    if (!window[identifier]) {
      window[identifier] = [];
    }
    const rudderanalytics = window[identifier];
    if (Array.isArray(rudderanalytics)) {
      if (rudderanalytics.snippetExecuted === true && window.console && logger.error) {
        logger.error(
          new Error(
            '[platform.event-tracking.rudderstack.sdk] RudderStack JavaScript SDK snippet included more than once.'
          )
        );
      } else {
        rudderanalytics.snippetExecuted = true;
        window.rudderAnalyticsBuildType = 'legacy';
        const sdkName = 'rsa.min.js';
        const scriptLoadingMode = 'async';
        const methods = [
          'setDefaultInstanceKey',
          'load',
          'ready',
          'page',
          'track',
          'identify',
          'alias',
          'group',
          'reset',
          'setAnonymousId',
          'startSession',
          'endSession',
          'consent',
        ];
        for (let i = 0; i < methods.length; i++) {
          const method = methods[i];
          rudderanalytics[method] = (function (methodName) {
            return function () {
              if (Array.isArray(window[identifier])) {
                rudderanalytics.push([methodName].concat(Array.prototype.slice.call(arguments)));
              } else {
                let _methodName;
                (_methodName = window[identifier][methodName]) === null ||
                  _methodName === void 0 ||
                  _methodName.apply(window[identifier], arguments);
              }
            };
          })(method);
        }
        try {
          // ! TEMP HACK: Removed from snippet because Vercel was choking on it after guidance from Rudderstack: https://littleotter.slack.com/archives/C05G4027QTH/p1728585010587349
          // new Function(
          //   'class Test{field=()=>{};test({prop=[]}={}){return prop?(prop?.property??[...prop]):import("");}}'
          // );
          window.rudderAnalyticsBuildType = 'modern';
        } catch (e) {}
        const head = document.head || document.getElementsByTagName('head')[0];
        const body = document.body || document.getElementsByTagName('body')[0];
        window.rudderAnalyticsAddScript = function (url, extraAttributeKey, extraAttributeVal) {
          const scriptTag = document.createElement('script');
          scriptTag.src = url;
          scriptTag.setAttribute('data-loader', 'RS_JS_SDK');
          if (extraAttributeKey && extraAttributeVal) {
            scriptTag.setAttribute(extraAttributeKey, extraAttributeVal);
          }
          if (scriptLoadingMode === 'async') {
            scriptTag.async = true;
          } else if (scriptLoadingMode === 'defer') {
            scriptTag.defer = true;
          }
          if (head) {
            head.insertBefore(scriptTag, head.firstChild);
          } else {
            body.insertBefore(scriptTag, body.firstChild);
          }
        };
        window.rudderAnalyticsMount = function () {
          (function () {
            if (typeof globalThis === 'undefined') {
              const getGlobal = function getGlobal() {
                if (typeof self !== 'undefined') {
                  return self;
                }
                if (typeof window !== 'undefined') {
                  return window;
                }
                return null;
              };
              const global = getGlobal();
              if (global) {
                Object.defineProperty(global, 'globalThis', {
                  value: global,
                  configurable: true,
                });
              }
            }
          })();
          window.rudderAnalyticsAddScript(
            ''.concat(sdkBaseUrl, '/').concat(window.rudderAnalyticsBuildType, '/').concat(sdkName),
            'data-rsa-write-key',
            writeKey
          );
        };
        if (typeof Promise === 'undefined' || typeof globalThis === 'undefined') {
          window.rudderAnalyticsAddScript(
            'https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount'
          );
        } else {
          window.rudderAnalyticsMount();
        }

        rudderanalytics.load(writeKey, dataplaneUrl, {
          pluginSdkBaseUrl: `${sdkBaseUrl}/modern/plugins`,
          destSDKBaseURL: `${sdkBaseUrl}/modern/js-integrations`,
        });

        rudderanalytics.ready(() => {
          logger.debug('[platform.event-tracking.rudderstack.sdk] Rudderstack SDK loaded & ready');
        });
      }
    }
  })();
};
