const proxyURL = process.env.NEXT_GEN_PROXY_URL;

// !CAREFUL CHANGING PROXY ROUTES
// These proxy routes do not represent routes within apps/legacy-member. They refer to routes across the NextJS proxy server and are actually references to routes in apps/client.
// We must resolve them with absolute URLs and consumers must navigate to them using window.location.href= in order to get “across the proxy boundary”. Attempting to navigate to these routes using router.push or Link components that utilize react-router within the legacy app will not work.

export const loginProxyUrl = `${proxyURL}/login`;
export const logoutProxyUrl = `${proxyURL}/logout`;
export const supportProxyUrl = `${proxyURL}/contact/support`;
export const appointmentsProxyUrl = `${proxyURL}/care/appointments`;
export const insuranceProxyUrl = `${proxyURL}/insurance/client`;
